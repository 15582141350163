@import "~bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');

$phone-width: 599px;
$tablet-width: 622px;
$desktop-width: 1024px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
      @content;
    }
  }

@keyframes animStar {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-2000px);
  }
}

.background {
    overflow: hidden;
    background-image: url('./img/bl.jpg');
    height: 92vh;
    background-repeat:round;
}

.background img {
    position: absolute;
}

.top-container {
    text-align: center;
    position: relative;
    color: #FFF;
    top: 50%;
    left: 50%;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    margin: 0 auto;
    //width: 40%;
    padding: 4px;
    letter-spacing: 0;
    @include phone {
        font-size: 12px;
    }
    @include tablet {
        font-size: 20px;
    }
    @include desktop {
        font-size: 35px;
    }
}
.work-button {
    margin-top: 1rem;
    padding: .2rem 2rem;
    background-color:#ffefde;
    color:black;
    border-radius: 2rem;
    border: 1px #122444 solid;
    outline: none;
    -moz-outline-style: none;
    font-size: 1.2rem;
    font-weight: 900;
}

.work-button:hover {
    background-color: #9797c4;
    color: black;
    cursor: pointer;
}
.work-button:focus {
    outline: 0;
}

.flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#footer {
    background-color:white;
    color: white;
}

.btn-style {
    font-size: 1.25rem;
}

.home-style {
    font-size: 1.75rem;
}

@keyframes move {
  from { top: -80px;}
  to   { top: 0; }
}

.fade-in-nav {
    animation: move 1s alternate;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
}
.fade-in-nav + .content-containers {
    padding-top: 60px;
}

.progress {
    height: 1.5rem;
    font-size: 1rem;
}


.mytext {
    font-size: 1.2rem;
}

.btn-style:hover {
    cursor: pointer;
}
.home-style:hover {
    cursor: pointer !important;
}




@keyframes slideBot {
    0% {opacity: 0; transform: translateY(300px);}
    100% {opacity: 1; transform: translateY(0)}
}
.hidden, .hiddenBot, .hiddenRight {
    //opacity: 0;
}
.slideBot {
    animation: slideBot 1s;
}
@keyframes slideLeft {
    0% {opacity: 0; transform: translateX(-300px);}
    100% {opacity: 1; transform: translateX(0)}
}
@keyframes slideRight {
    0% {opacity: 0; transform: translateX(300px);}
    100% {opacity: 1; transform: translateX(0)}
}

.slideRight {
    animation: slideRight 1s;
}

.slideLeft {
    animation: slideLeft 1s;
}


.modal-card {
    display: none;
    position: absolute;
    width: 45%;
    top: 10%;
    left: 50%;
    height: 85%;
    background-color: #f8f9fa;
    z-index: 101;
    transform: translate(-50%,-10%);
    -ms-transform: translate(-50%,-10%);
}

.visual video,.visual img {
    width: 100%;
    height: auto;
    border-bottom: 2px solid black;
}

.modal-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.5rem;
}

.modal-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}





