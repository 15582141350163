.main {
  text-align: center;
  height:80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

}

.title {
 font-size: 42px;
}


.image {
  font-size: 23px;
  margin: 10px;
  display: inline-block;
  line-height: 100px;
}

.image-container img {
  height: 27em;
  width: 35em;
}

.image-container {
  vertical-align: middle;
  display: inline-block;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  width: 99%;

}

.prev, .next {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
  cursor: pointer;
  color: black;
  transition: 0.10s ease;
}

.next {
  margin-left: 5px;
}

.prev {
  margin-right: 5px;
}

.prev:hover, .next:hover {
  color: white;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
}

.middle-icon {
  margin-left: auto;
  margin-right: auto;
  display: table;
}



#contact {
  margin-top: 2em;
  font-size: 8em;
  font-family: monospace;
}

.box{
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }

  .card{
    position: relative;
    width: 350px;
    height: 380px;
    background: #fff;
    margin-top: 6em;
    border-radius: 4px;
    box-shadow:0 2px 10px rgba(0,0,0,.2);
  }

  .card:before,
  .card:after
  {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    transition: 0.5s;
    z-index:-1;
  }
  .card:hover:before{
  transform: rotate(20deg);
  box-shadow: 0 2px 20px rgba(0,0,0,.2);
  }
  .card:hover:after{
  transform: rotate(10deg);
  box-shadow: 0 2px 20px rgba(0,0,0,.2);
  }
  .card .imgBx{
  position: absolute;
  top: 8px;
  left: 10px;
  bottom: 8px;
  right: 10px;
  background: #222;
  transition: 0.5s;
  z-index: 1;
  }

  .card:hover .imgBx
  {
    bottom: 80px;
  }

  .card .imgBx img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }

  .card .details{
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 10px;
      height: 60px;
      text-align: center;
  }

  .card .details h2{
   margin: 0;
   padding: 0;
   font-weight: 600;
   font-size: 18px;
   color: #777;
   text-transform: uppercase;
  }

  .card .details a{
  font-weight: 500;
  font-size: 14px;
  color: #f38695;
  display: inline-block;
  margin-top: 5px;
}



@media screen and (min-width: 800px) and (max-width: 1400px) {
  #contact {
    font-size: 7em;
  }
}

@media screen and (max-width: 800px) {

  #contact {
    font-size: 6em;
  }

  .card{
    position: relative;
    width: 250px;
    height: 300px;
  }

  .card .details h2 {
    font-size: 12px;
  }

  .image-container img{
    height: 20em;
    width: 95%;
  }

  .image-container{

    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .my-photos {
    width: 100%;
  }



}
